import axiosInstance from './AxiosInstance';


export function getNFTApi(filterData) {
  let url = `ads-nft?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;


  if (filterData?.pageNumber) {
    url += `&pageNumber=${filterData?.pageNumber}`;
  }
  if (filterData?.pageSize) {
    url += `&pageSize=${filterData?.pageSize}`;
  }

  return axiosInstance.get(
    `${url}`,
  );
}
export const AddNFTApi = (data) => {
  return axiosInstance.post('/ads-nft/create', data);
};
