import { GET_COLLECTIONS, LOADING_TOGGLE_ACTION, CONFIRMED_CREATE_COLLECTIONS } from "../../actions/collections/CollectionTypes";


const initialState = {
    collectionList: [],
    meta: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 10,
        total: 0,
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};
export function CollectionssReducer(state = initialState, actions) {
    if (actions.type === GET_COLLECTIONS) {
        return {
            ...state,
            collectionList: actions.payload?.data?.adsCollection,
            meta: actions.payload?.meta,
            showLoading: false
        };
    }
    if (actions.type === CONFIRMED_CREATE_COLLECTIONS) {
        const newCollectionList = [...state.collectionList];
        newCollectionList.push(actions.payload);

        return {
            ...state,
            collectionList: newCollectionList,
            showLoading: false,
            successMessage: "Collection Created Successfully!",
        };
    }


    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    return state;
}
