import { applyMiddleware, combineReducers, compose, createStore, } from 'redux';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import todoReducers from './reducers/Reducers';
import { TransactionsReducer } from './reducers/transactions/TransactionReducer';
import { UsersReducer } from './reducers/users/UsersReducer';
import TokensReducer from './reducers/tokens/TokensReducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { CollectionssReducer } from './reducers/collection/CollectionReducer';
import { NFTReducer } from './reducers/nft/NftReducer';
import { ZKLoginReducer } from './reducers/zkLogin/ZkReducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const authTransform = {
    in: (state) => ({
        auth: state.auth,
    }),
    out: (state) => state,
};
const persistConfig = {
    key: 'root',
    transforms: [authTransform],
    whitelist: ['auth'],
    storage,
    version: 1,
};
const reducers = combineReducers({
    users: UsersReducer,
    transactions: TransactionsReducer,
    auth: AuthReducer,
    tokens: TokensReducer,
    collections: CollectionssReducer,
    nft: NFTReducer,
    zkLogin: ZKLoginReducer,
    todoReducers,
    //form: reduxFormReducer,	

});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, composeEnhancers(middleware));

export const persistor = persistStore(store);
//const store = createStore(rootReducers);

// export const store = createStore(reducers, composeEnhancers(middleware));
