
import axiosInstance from './AxiosInstance'

export function getTransactionApi(filterData) {
    let url = `/admin/transactions?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;
    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if(filterData?.search){
        url += `&toAddress=${filterData?.search}`;

    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }
    if (filterData?.type) {
        url += `&type=${filterData?.type}`;
    }
    if (filterData?.status) {
        url += `&status=${filterData?.status}`;
    }
    return axiosInstance.get(
        `${url}`,
    );
}

export function updateTransactionStatusApi(id, status) {
    return axiosInstance.put(
        `/admin/transaction/${id}?status=${status}`
    );
}
