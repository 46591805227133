export const UserAuthErrors = {
    NOT_VERIFIED: "User is not verified",
    PASSWORD_FORMAT: "Password must be at least 8 characters long and contain at least 1 lowercase, 1 uppercase, 1 number, and 1 special character"
}

export const OtpTypeEnum = {
    RESEND: 'resend',
    REGISTER: 'register',
}

export const UserBalanceTypeEnum = {
    TRANSFER: 'transfer',
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
    CONVERT: 'convert',
    SPIN_REWARD: 'spin_reward',
    GAME_REWARD: 'game_reward',
}
export const UserRoleEnum = {
    NATIVE: 'NATIVE',
    DEVELOPER: 'DEVELOPER',
    ADMIN: 'ADMIN',
    SUB_ADMIN: 'SUB_ADMIN',

}

export const UserRewardsStatus = {
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
}

export const TransactionStatusEnum = {
    PROCESSING: 'PROCESSING',
    BROADCAST: 'BROADCAST',
    PENDING: 'PENDING',
    CONFIRM: 'CONFIRM',
    CANCEL: 'CANCEL',
    ERROR: 'ERROR',
    REJECTED: 'REJECTED',
}

export const NftPropertiesEnum = {
    CLICKS: 'Clicks',
    IMPRESSIONS: 'Impressions',

}

export const ApiStatusEnum={
    BANNERIMAGE:"bannerImage",
    PREVIEWIMAGE:'previewImage',
    TOKEN:'token',
    LOGOIMAGE:'logoImage',

}