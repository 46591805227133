import { GET_ZK_LOGIN, LOADING_TOGGLE_ACTION } from "../../actions/zkLogin/ZKTypes";


const initialState = {
    zkLoginList: [],
    meta: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 10,
        total: 0,
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};
export function ZKLoginReducer(state = initialState, actions) {
    if (actions.type === GET_ZK_LOGIN) {
        return {
            ...state,
            zkLoginList: actions.payload?.data,
            meta: actions.payload?.meta,
            showLoading: false
        };
    }
  

    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    return state;
}
