import { CONFIRMED_GET_USERS, LOADING_TOGGLE_ACTION, CONFIRMED_EDIT_USER_ACTION, FAILED_EDIT_USER_ACTION } from "../../actions/users/UsersTypes";


const initialState = {
    usersList: [],
    meta: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 10,
        total: 0,
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function UsersReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_GET_USERS) {
        return {
            ...state,
            usersList: actions.payload?.data,
            meta: actions.payload?.meta,
            showLoading: false
        };
    }
    if (actions.type === CONFIRMED_EDIT_USER_ACTION) {
        const newUserssList = [...state.usersList];
        const rewardIndex = newUserssList.findIndex(
            (reward) => reward.id === actions.payload.id,
        );

        newUserssList[rewardIndex] = actions.payload;
        return {
            ...state,
            usersList: newUserssList,
            errorMessage: ''
        };
    }
    if (actions.type === FAILED_EDIT_USER_ACTION) {
        return {
            ...state,
            errorMessage: actions.payload,
        };
    }
    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    return state;
}
