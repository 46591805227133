import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getUsersAction, loadingToggleAction, updateUserRoleAction, updateUserStatusAction } from '../../../../store/actions/users/UsersAction';
import { UserRoleEnum } from '../../../../helpers/Enums';
import swal from "sweetalert";
import { PaginationControl } from 'react-bootstrap-pagination-control';

const AllUsers = ({ usersList, loading, type, meta, sortBy, setUseCount, search }) => {
  const dispatch = useDispatch()
  let navigate=useNavigate()
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getUsersAction({ type, sortBy, search }))
  }, [dispatch, sortBy, type, search])
  const onPageChange = (i) => {
    dispatch(loadingToggleAction(true));
    dispatch(getUsersAction({
        type, pageNumber: i, pageSize: meta?.pageSize
      }))
  };
  useEffect(() => {
    setUseCount(meta?.total)
  }, [meta, setUseCount])
  return (
    <>
      <div id="history_wrapper" className="table-responsive dataTablehistory">
        <div className="dataTables_wrapper no-footer">
          <table id="example" className="table shadow-hover dataTable display" style={{ minWidth: "845px" }}>
            <thead>
              <tr>
                <th>E-mail</th>
                <th>Name</th>
                <th>Contact Number</th>
                <th>Address</th>
                <th>Status</th>
                <th>Role</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                                loading &&
                <tr>
                  <td colSpan="6">
                    <div className="text-center">
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                    </div>

                  </td>
                </tr>
              }


              {!loading && usersList?.length > 0 && usersList?.map((item, index) => (
                <tr key={index}>
                  <td>
                                        {item?.email}
                                    </td>

                  <td> <p className="text-truncate d-inline-block mb-0" style={{ maxWidth: '200px' }}> {`${item.firstName ?? '-'} ${item.lastName ?? '-'}`}</p></td>
                                    <td>
                                        {item?.contactNumber || '-'}
                  </td>
                  <td>
                    <p className="text-truncate d-inline-block mb-0" style={{ maxWidth: '200px' }}> {item?.address || '-'}</p>
                  </td>
                  <td>
                    <div className="form-check form-switch " data-toggle="tooltip" data-placement="top" title={item.isBlocked ? 'Blocked' : 'Active'}>
                      <input className="form-check-input" checked={!item.isBlocked} type="checkbox" role="switch" onChange={(e) => {
                          swal({
                            title: 'Are you sure?',
                            text: 'Once accepted, you will not be able to revert this!',
                            icon: 'warning',
                            buttons: ['Cancel', 'Accept'],
                          }).then((willDelete) => {
                            if (willDelete) {
                              dispatch(updateUserStatusAction(item.id, !item.isBlocked))
                            }
                          })
                                            }


                                            }
                      />
                    </div>
                  </td>
                  <td>
                    <p className="mb-0">{item?.role?.name || '-'}</p>
                  </td>

                  <td className="text-center">
                    <div className="d-flex justify-content-end">
                      {item?.role?.name === UserRoleEnum.NATIVE && (
                        <span
                          className="btn btn-primary shadow btn-xs sharp me-3"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Make Developer"
                          onClick={() => {
                            swal({
                              title: 'Are you sure?',
                              text: 'You are about to give DEVELOPER access to this user!',
                              icon: 'warning',
                              buttons: ['Cancel', 'Accept'],
                            }).then((willDelete) => {
                              if (willDelete) {
                                dispatch(
                                  updateUserRoleAction(
                                    item.id,
                                    UserRoleEnum.DEVELOPER
                                  )
                                );
                              }
                            });
                          }}
                        >
                          <i className="fa-solid fa-user-pen"></i>
                        </span>
                      )}
                      {item?.role?.name === UserRoleEnum.DEVELOPER && (
                        <span
                          className="btn btn-danger shadow btn-xs sharp me-3"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Make Native User"
                          onClick={() => {
                            swal({
                              title: 'Are you sure?',
                              text: 'You are about to take away DEVELOPER access of this user!',
                              icon: 'warning',
                              buttons: ['Cancel', 'Accept'],
                            }).then((willDelete) => {
                              if (willDelete) {
                                dispatch(
                                  updateUserRoleAction(
                                    item.id,
                                    UserRoleEnum.NATIVE
                                  )
                                );
                              }
                            });
                          }}
                        >
                          <i className="fa-solid fa-user"></i>
                        </span>
                      )}
                      <span
                        className="btn btn-danger shadow btn-xs sharp me-3"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View User"
                        onClick={() => navigate(`/view-user?id=${item?.id}`)}
                      >
                        <i className="fa-regular fa-eye"></i>
                      </span>
                    </div>
                  </td>
                </tr>
              ))}

              {!loading && usersList?.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No Users Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Showing {meta?.pageNumber + 1} to {meta?.pageCount} of{' '}
              {meta?.pageSize} entries
            </div>

            {meta?.pageCount > 1 && (
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Link
                  className="paginate_button previous "
                  to="/transactions"
                  onClick={() => onPageChange(0)}
                >
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <span>
                  <PaginationControl
                    page={meta?.pageNumber + 1}
                    between={3}
                    total={meta?.total}
                    limit={meta.pageSize}
                    changePage={(page) => {
                      onPageChange(page - 1);
                    }}
                    ellipsis={1}
                  />
                </span>
                <Link
                  className="paginate_button next"
                  to="/transactions"
                  onClick={() => onPageChange(meta?.pageCount - 1)}
                >
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ users }) => {
  const { usersList, showLoading, errorMessage, successMessage, meta } = users;
  return {
    usersList: usersList,
    loading: showLoading,
    errorMessage: errorMessage,
    successMessage: successMessage,
    meta: meta,
  };
};

export default connect(mapStateToProps)(AllUsers);
