import React, { useEffect} from 'react';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getViewUsersAction, loadingToggleAction } from '../../../store/actions/users/UsersAction'
import { displayDecimal, formatDateTime, replaceUnderscore } from '../../../helpers/Utils';
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const ViewUser = ({ usersList, loading, meta }) => {
    const query = useQuery();
    const id = query.get('id');
    const dispatch = useDispatch()
  
    useEffect(() => {
        dispatch(loadingToggleAction(true));
        dispatch(getViewUsersAction({id}))
    }, [dispatch, id])

    const onPageChange = (i) => {
        dispatch(loadingToggleAction(true));
        dispatch(getViewUsersAction({
            id, pageNumber: i, pageSize: meta?.pageSize
        }))
    };
    return (
        <>
            <div id="history_wrapper" className="table-responsive dataTablehistory">
                <div className="dataTables_wrapper no-footer">
                    {!loading && usersList && <div style={{ display: "flex", justifyContent: "space-between",alignItems:"center" }}>
                       <p className="text-truncate d-inline-block mb-0" style={{ fontSize: "16px" }}>
                            <span>Email : </span> {usersList?.user?.email ?? '-'}</p>
                        <p className="text-truncate d-inline-block mb-0" style={{ fontSize: "16px" }}>
                            <span>Total Senet Balance : </span> {usersList?.totalSnt ?? '-'}</p>
                    </div>}
                    <table id="example" className="table shadow-hover dataTable display" style={{ minWidth: "845px" }}>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Last Reward Claim</th>
                                <th>Symbol</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading &&
                                <tr>
                                    <td colSpan="6">
                                        <div className="text-center">
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                                        </div>

                                    </td>
                                </tr>
                            }
                            {usersList && usersList?.userTokenBalances?.map((item,index) => (
                                <tr key={index}>
                                    <td>
                                        {item?.id}
                                    </td>
                                    <td> <p className="text-truncate d-inline-block mb-0 text-uppercase"
                                        style={{ maxWidth: '200px' }}>{replaceUnderscore(item?.type)}</p></td>
                                    <td> {displayDecimal(item.amount) }</td>
                                    <td>
                                        <p className="text-truncate d-inline-block mb-0" style={{ maxWidth: '200px' }}>
                                            {!!item?.lastRewardClaim ? formatDateTime(item?.lastRewardClaim) : '-'}</p>
                                    </td>
                                    <td>
                                      {item.symbol}
                                    </td>
                                </tr>
                            ))}
                            {usersList && usersList?.userTokenBalances?.length === 0 && (
                                <tr>
                                    <td colSpan={6} style={{ textAlign: 'center' }}>
                                        No Users Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                        <div className="dataTables_info">
                            Showing {meta?.pageNumber + 1} to {meta?.pageCount} of{' '}
                            {meta?.pageSize} entries
                        </div>

                        {meta?.pageCount > 1 && (
                            <div
                                className="dataTables_paginate paging_simple_numbers mb-0"
                                id="application-tbl1_paginate"
                            >
                                <Link
                                    className="paginate_button previous "
                                    to="/transactions"
                                    onClick={() => onPageChange(0)}
                                >
                                    <i className="fa fa-angle-double-left"></i>
                                </Link>
                                <span>
                                    <PaginationControl
                                        page={meta?.pageNumber + 1}
                                        between={3}
                                        total={meta?.total}
                                        limit={meta.pageSize}
                                        changePage={(page) => {
                                            onPageChange(page - 1);
                                        }}
                                        ellipsis={1}
                                    />
                                </span>
                                <Link
                                    className="paginate_button next"
                                    to="/transactions"
                                    onClick={() => onPageChange(meta?.pageCount - 1)}
                                >
                                    <i className="fa fa-angle-double-right"></i>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ users }) => {
    const { usersList, showLoading, errorMessage, successMessage, meta } = users;
    return {
        usersList: usersList,
        loading: showLoading,
        errorMessage: errorMessage,
        successMessage: successMessage,
        meta: meta,
    };
};

export default connect(mapStateToProps)(ViewUser);
