import { AddCollectionApi, getCollectionApi } from "../../../services/CollectionService";
import { GET_COLLECTIONS, LOADING_TOGGLE_ACTION, CONFIRMED_CREATE_COLLECTIONS } from "./CollectionTypes";
import swal from 'sweetalert';

export function getCollectionAction(filterData) {
    return (dispatch, state) => {
        getCollectionApi(filterData)
            .then((response) => {
                dispatch(
                    {
                        type: GET_COLLECTIONS,
                        payload: {
                            ...response.data, meta: {
                                ...response?.data.data.meta,
                                 sortBy: filterData?.sortBy
                            }
                        },
                    }
                );
            })
            .catch((error) => {
                dispatch(
                    {
                        type: GET_COLLECTIONS,
                        payload: {
                            data: [],
                            meta: state.meta
                        }
                    }
                );
            });

    };
}

export function createCollectionAction(collectionData, navigate) {
    return (dispatch) => {
        dispatch(loadingToggleAction(true));
        AddCollectionApi(collectionData).then((response) => {
            const collectionData = {
                ...response.data.data,
                id: response.data.data.id,
            };
            dispatch({
                type: CONFIRMED_CREATE_COLLECTIONS,
                payload: collectionData,
            })
            dispatch(loadingToggleAction(false));

            swal({
                title: 'Success',
                text: 'Collection created successfully',
                icon: 'success',
            }).then((ok) => {
                if (ok) {
                    navigate('/collection');
                }
            });
        }).catch((error) => {
            dispatch(loadingToggleAction(false));

            const responeError = error?.response?.data?.message;
            swal('Error', `${responeError}`, 'error');
        })
    };
}
export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}