export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
export const regexValidatePassword = (password) => {
    return passwordRegex.test(password);
}

export const replaceUnderscore = (text) => {
    return text.replace(/_/g, ' ');
}

export const displayDecimal = (decimalNumber) => {
    const decimalStr = decimalNumber?.toString();
    const [integerPart, decimalPart] = decimalStr?.split('.');
    const nonZeroIndex = decimalPart ? decimalPart?.split('').findIndex(digit => digit !== '0') : -1;
    const displayedDecimal = nonZeroIndex !== -1
        ? `${integerPart}.${decimalPart?.substring(0, Math.min(nonZeroIndex + 2, decimalPart.length))}`
        : integerPart;

    return displayedDecimal;
}
export const formatDateTime=(dateString) =>{
    // Create a new Date object from the given date string
    const date = new Date(dateString);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    // Extract time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the date and time as desired
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    // Combine date and time
    return `${formattedDate} ${formattedTime}`;
}