import axios from 'axios';
/**
 * Fetches the guest users from the API.
 * @param {number} filterData.pageNumber - The page number.
 * @param {number} filterData.pageSize - The page size.
 * @returns {Promise} - The promise object.
 */
export function getGuestUserApi(filterData) {
  let url = 'https://api.gamesfi.live/user/get-wallet';

  
  if (filterData?.pageSize) {
    url += `?pageSize=${filterData.pageSize}`;
  }
  if (filterData?.pageNumber) {
    url += `&pageNumber=${filterData.pageNumber}`;
  }

  return axios.get(url);
}



