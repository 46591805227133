import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createCollectionAction } from '../../../store/actions/collections/CollectionActions';
import { ApiStatusEnum } from '../../../helpers/Enums';

const AddCollection = () => {
  const formObj = {
    name: '',
    category: '',
    externalLink: '',
    description: '',
    bannerImage: null,
    logoImage: null,
  };
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.collections.showLoading);
  const [formData, setFormData] = useState(formObj);
  const [errors, setErrors] = useState(formObj);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      'name',
      'description',
      'category',
      'externalLink',
      'bannerImage',
      'logoImage',
    ];

    const errorsObj = requiredFields.reduce((acc, field) => {
      if (!formData[field]) {
        const fieldName = field.replace(/([a-z])([A-Z])/g, '$1 $2'); // Add space between camelCase words

        acc[field] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is Required`;
      }
      return acc;
    }, {});

    setErrors(errorsObj);

    if (Object.keys(errorsObj).length > 0) {
      return;
    }

    if (!urlPattern.test(formData.externalLink)) {
      setErrors({ ...errors, externalLink: 'Enter valid URL' });
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('name', formData.name);
    bodyFormData.append('description', formData.description);
    bodyFormData.append('externalLink', formData.externalLink);
    bodyFormData.append('category', formData.category);

    if (formData.bannerImage) {
      bodyFormData.append('bannerImage', formData.bannerImage);
    }
    if (formData.logoImage) {
      bodyFormData.append('logoImage', formData.logoImage);
    }

    dispatch(createCollectionAction(bodyFormData, navigate, ))


  };

  const handleChange = (e, field) => {
    if (field === ApiStatusEnum.BANNERIMAGE || field === ApiStatusEnum.LOGOIMAGE) {
      setFormData({
        ...formData,
        [field]: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [field]: e.target.value,
      });
    }
    setErrors({
      ...errors,
      [field]: '', // Clear the error for the current field
    });
  };
  return (
    <div className="row">
      <div className="col-xxl-12">
        <div className="card">
          <div className="card-header border-0 pb-2 row">
            <div className="col-xl-6">
              <h4 className="heading me-2">Add Collection</h4>
            </div>
          </div>
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="row g-3 mb-3 ms-4 me-4">
                <div className="col-xl-6">
                  <label className="form-label">
                    Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control w-100"
                      value={formData.name}
                      onChange={(e) => handleChange(e, 'name')}
                    />
                  </div>
                  {errors.name && (
                    <div className="text-danger fs-12">{errors.name}</div>
                  )}
                </div>
                <div className="col-xl-6">
                  <label className="form-label">
                    Description
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control w-100"
                      value={formData.description}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  {errors.description && (
                    <div className="text-danger fs-12">
                      {errors.description}
                    </div>
                  )}
                </div>
              </div>

              <div className="row g-3 mb-3 ms-4 me-4">
                <div className="col-xl-6">
                  <label className="form-label">
                    Category
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control w-100"
                      value={formData.category}
                      onChange={(e) => handleChange(e, 'category')}
                    />
                  </div>
                  {errors.category && (
                    <div className="text-danger fs-12">{errors.category}</div>
                  )}
                </div>
                <div className="col-xl-6">
                  <label className="form-label">
                    Banner Image
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control w-100"
                      accept="image/*"
                      onChange={(e) => handleChange(e, 'bannerImage')}
                    />
                  </div>
                  {errors.bannerImage && (
                    <div className="text-danger fs-12">
                      {errors.bannerImage}
                    </div>
                  )}
                </div>
              </div>

              <div className="row g-3 mb-3 ms-4 me-4">
                <div className="col-xl-6">
                  <label className="form-label">
                    External Link
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control w-100"
                      value={formData.externalLink}
                      onChange={(e) => handleChange(e, 'externalLink')}
                    />
                  </div>
                  {errors.externalLink && (
                    <div className="text-danger fs-12">
                      {errors.externalLink}
                    </div>
                  )}
                </div>
                <div className="col-xl-6">
                  <label className="form-label">
                    Logo Image
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control w-100"
                      accept="image/*"
                      onChange={(e) => handleChange(e, 'logoImage')}
                    />
                  </div>
                  {errors.logoImage && (
                    <div className="text-danger fs-12">{errors.logoImage}</div>
                  )}
                </div>
              </div>
              <div className="row g-3 text-center mt-5 pb-5">
                <div className="col-xl-12">
                  <button
                    type="submit"
                    className="btn flex-fill btn-primary py-2 fs-5 text-uppercase px-5"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      'Create Collection'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCollection;
