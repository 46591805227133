import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import Select from 'react-select';
import ImageViewModal from './ImageViewModal';
import {  useNavigate } from 'react-router-dom';

import { getCollectionAction,loadingToggleAction } from '../../../store/actions/collections/CollectionActions';

export const sortOptions = [
  { value: 'ASC', label: 'Sort by ASC' },
  { value: 'DESC', label: 'Sort by DESC' },
];

const Collection = ({ allCollection, isLoading, meta }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [selectedSort, setSelectedSort] = useState(sortOptions[1]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProductImage, setSelectedProductImage] = useState('');
  useEffect(() => {
      const sortBy = selectedSort.value
      dispatch(loadingToggleAction(true));
      dispatch(getCollectionAction({ sortBy }));
  }, [dispatch, selectedSort]);
  const onPageChange = (i) => {
      dispatch(loadingToggleAction(true));
      dispatch(
          getCollectionAction({
              pageNumber: i,
              pageSize: meta?.pageSize,
          })
      );
  };

  const openModal = (image, event) => {
    event.stopPropagation();
    setSelectedProductImage(image);
    setModalOpen(true);
  };
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body pt-3 pb-3">
              <div className="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                <div>
                  <h4 className="heading mb-0">Collections</h4>
                </div>

                <div className=" d-flex align-items-center justify-content-between flex-wrap">
                  <button
                    className="btn btn-primary me-2"
                    id="js-programmatic-enable"
                    onClick={() => navigate('/add-collection')}
                  >
                    Create Collection
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end  pb-5 pr-10 flex-wrap">
          <Select
            className="custom-react-select mb-xl-0 mb-3 "
            defaultValue={selectedSort}
            value={selectedSort}
            onChange={setSelectedSort}
            options={sortOptions}
            placeholder="Sort by"
            style={{
              lineHeight: '40px',
              color: '#7e7e7e',
              paddingLeft: ' 15px',
            }}
          />
        </div>
        <div className="row">
          {isLoading && (
            <div className="text-center">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          )}

          {!isLoading &&
            allCollection?.map((item, index) => (
              <div key={index} className="col-xl-4 col-lg-6 col-sm-6">
                <div className="card cursor-pointer">
                  <div className="card-body">
                    <div className="new-arrival-product">
                      <div className="new-arrivals-img-contnent">
                        {item?.logoImageHash && (
                          <span
                            className="eye-iconCSS "
                            onClick={(event) =>
                              openModal(item?.logoImageHash, event)
                            }
                          >
                            <i className="bi bi-eye-fill "></i>
                          </span>
                        )}
                        <img
                          className="img-fluid rounded mb-3 customImageCss"
                          src={item?.bannerImageHash}
                          alt=""
                        />
                      </div>
                      <div className="new-arrival-content  mt-3">
                        <h4 className="card-title fs-4 cursor-pointer">
                          {item?.name}
                        </h4>
                        <div class="d-flex justify-content-between">
                          <div class="text-start">
                            <p class="mb-2">
                              Category Name :{' '}
                              <strong class="text-primary">
                                {item?.category}
                              </strong>
                            </p>
                          </div>
                          <div class="text-end">
                            <p class="mb-2">
                              Total Nft :{' '}
                              <strong class="text-primary">
                                {item?.noOfAdsNFT}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div id="history_wrapper" className="table-responsive dataTablehistory">
            <div className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                    <div className="dataTables_info">
                        Showing {meta?.pageNumber + 1} to {meta?.pageCount} of{' '}
                        {meta?.pageSize} entries
                    </div>

                    {meta?.pageCount > 1 && (
                        <div
                            className="dataTables_paginate paging_simple_numbers mb-0"
                            id="application-tbl1_paginate"
                        >
                            <Link
                                className="paginate_button previous"
                                to="/projects"
                                onClick={() => onPageChange(0)}
                            >
                                <i className="fa fa-angle-double-left"></i>
                            </Link>
                            <span>
                                <PaginationControl
                                    page={meta?.pageNumber + 1}
                                    between={3}
                                    total={meta?.total}
                                    limit={meta.pageSize}
                                    changePage={(page) => {
                                        onPageChange(page - 1);
                                    }}
                                    ellipsis={1}
                                />
                            </span>
                            <Link
                                className="paginate_button next"
                                to="/projects"
                                onClick={() => onPageChange(meta?.pageCount - 1)}
                            >
                                <i className="fa fa-angle-double-right"></i>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
          <ImageViewModal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              selectedProductImage={selectedProductImage}
          />
    </>
  );
};

const mapStateToProps = ({ collections }) => {
  const { collectionList, showLoading, errorMessage, successMessage, meta } =
    collections;
  return {
    allCollection: collectionList,
    loading: showLoading,
    errorMessage: errorMessage,
    successMessage: successMessage,
    meta: meta,
  };
};

export default connect(mapStateToProps)(Collection);
