import { useState } from 'react';

import { useEffect } from 'react';
import Select from 'react-select';
import useAllToken from '../../../hooks/useAllToken';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getCollectionAction } from '../../../store/actions/collections/CollectionActions';
import { useNavigate } from 'react-router-dom';
import { createNFTAction } from '../../../store/actions/nft/NFTActions';
import { ApiStatusEnum } from '../../../helpers/Enums';

const AddNft = ({ allCollection }) => {
  const formObj = {
    name: '',
    externalLink: '',
    description: '',
    bannerImage: null,
    previewImage: null,
    price: '',
    collection: '',
    token: '',
  };
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(state => state.nft.showLoading);

  const [formData, setFormData] = useState(formObj);
  const [errors, setErrors] = useState(formObj);
  const [collectionsOption, setCollectionsOption] = useState([]);
  const { allTokens, isTokenLoading } = useAllToken();

  useEffect(() => {
    dispatch(getCollectionAction({pageSize:1000}));

    const collectionOptions = allCollection?.map(
        (item) => ({
          label: item.name,
          value: item.id,
        })
      );
      setCollectionsOption(collectionOptions);
  }, [dispatch]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      'name',
      'description',
      'externalLink',
      'bannerImage',
      'previewImage',
      'price',
      'collection',
      'token',
    ];

    const errorsObj = requiredFields.reduce((acc, field) => {
      if (!formData[field]) {
        const fieldName = field.replace(/([a-z])([A-Z])/g, '$1 $2'); // Add space between camelCase words

        acc[field] = `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is Required`;
      }
      return acc;
    }, {});

    setErrors(errorsObj);

    if (Object.keys(errorsObj).length > 0) {
      return;
    }

    if (!urlPattern.test(formData.externalLink)) {
      setErrors({ ...errors, externalLink: 'Enter valid URL' });
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('name', formData.name);
    bodyFormData.append('description', formData.description);
    bodyFormData.append('externalLink', formData.externalLink);
    bodyFormData.append('price', String(formData.price))
    bodyFormData.append('collectionId', String(formData.collection));
    bodyFormData.append('tokenId', String(formData.token));

    if (formData.bannerImage) {
      bodyFormData.append('bannerImage', formData.bannerImage);
    }
    if (formData.previewImage) {
      bodyFormData.append('previewImage', formData.previewImage);
    }
    dispatch(createNFTAction(bodyFormData, navigate,))

    
  };

  const handleChange = (e, field) => {
    if (field === ApiStatusEnum.BANNERIMAGE || field === ApiStatusEnum.PREVIEWIMAGE) {
      setFormData({
        ...formData,
        [field]: e.target.files[0],
      });
    }
    else if (field === ApiStatusEnum.TOKEN) {
      setFormData({
        ...formData,
        [field]: e.id,
      });

    }

    else {
      setFormData({
        ...formData,
        [field]: e?.target?.value || e.value,
      });
    }
    setErrors({
      ...errors,
      [field]: '', // Clear the error for the current field
    });
  };
  return (
    <div className="row">
      <div className="col-xxl-12">
        <div className="card">
          <div className="card-header border-0 pb-2 row">
            <div className="col-xl-6">
              <h4 className="heading me-2">Add NFT</h4>
            </div>
          </div>
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="row g-3 mb-3 ms-4 me-4">
                <div className="col-xl-6">
                  <label className="form-label">
                    Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control w-100"
                      value={formData.name}
                      onChange={(e) => handleChange(e, 'name')}
                    />
                  </div>
                  {errors.name && (
                    <div className="text-danger fs-12">{errors.name}</div>
                  )}
                </div>
                <div className="col-xl-6">
                  <label className="form-label">
                    Description
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control w-100"
                      value={formData.description}
                      onChange={(e) => handleChange(e, 'description')}
                    />
                  </div>
                  {errors.description && (
                    <div className="text-danger fs-12">
                      {errors.description}
                    </div>
                  )}
                </div>
              </div>
              <div className="row g-3 mb-3 ms-4 me-4">
                <div className="col-xl-6">
                  <label className="form-label">
                    Price
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control w-100"
                      value={formData.price}
                      onChange={(e) => handleChange(e, 'price')}
                    />
                  </div>
                  {errors.price && (
                    <div className="text-danger fs-12">{errors.price}</div>
                  )}
                </div>
                <div className="col-xl-6">
                  <label className="form-label">
                    Token
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <Select
                      onChange={(e) => {
                        handleChange(e, 'token')
                      }}
                      className="custom-react-select mb-xl-0 mb-3 w-100 z-index-9"
                      options={allTokens}
                      placeholder="Select Tokens"
                      isSearchable={true}
                      isDisabled={isTokenLoading}
                      isLoading={isTokenLoading}
                      closeMenuOnSelect={true}
                      closeMenuOnScroll={false}
                      style={{
                        lineHeight: '40px',
                        color: '#7e7e7e',
                        paddingLeft: ' 15px',
                      }}
                    />
                  </div>
                  {errors.token && (
                    <div className="text-danger fs-12">{errors.token}</div>
                  )}
                </div>
              </div>
              <div className="row g-3 mb-3 ms-4 me-4">
                <div className="col-xl-6">
                  <label className="form-label">
                    Collection
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <Select
                      onChange={(e) => {
                        handleChange(e, 'collection')
                      }}
                      className="custom-react-select mb-xl-0 mb-3 w-100 z-index-9"
                      options={collectionsOption}
                      placeholder="Select Collections"
                      style={{
                        lineHeight: '40px',
                        color: '#7e7e7e',
                        paddingLeft: ' 15px',
                      }}
                    />
                  </div>
                  {errors.collection && (
                    <div className="text-danger fs-12">{errors.collection}</div>
                  )}
                </div>
                <div className="col-xl-6">
                  <label className="form-label">
                    Banner Image
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control w-100"
                      accept="image/*"
                      onChange={(e) => handleChange(e, 'bannerImage')}
                    />
                  </div>
                  {errors.bannerImage && (
                    <div className="text-danger fs-12">
                      {errors.bannerImage}
                    </div>
                  )}
                </div>
              </div>

              <div className="row g-3 mb-3 ms-4 me-4">
                <div className="col-xl-6">
                  <label className="form-label">
                    External Link
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control w-100"
                      value={formData.externalLink}
                      onChange={(e) => handleChange(e, 'externalLink')}
                    />
                  </div>
                  {errors.externalLink && (
                    <div className="text-danger fs-12">
                      {errors.externalLink}
                    </div>
                  )}
                </div>
                <div className="col-xl-6">
                  <label className="form-label">
                    Preview Image
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="file"
                      className="form-control w-100"
                      accept="image/*"
                      onChange={(e) => handleChange(e, 'previewImage')}
                    />
                  </div>
                  {errors.previewImage && (
                    <div className="text-danger fs-12">{errors.previewImage}</div>
                  )}
                </div>
              </div>
              <div className="row g-3 text-center mt-5 pb-5">
                <div className="col-xl-12">
                  <button
                    type="submit"
                    className="btn flex-fill btn-primary py-2 fs-5 text-uppercase px-5"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      'Add NFT'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ collections }) => {
  const { collectionList } = collections;
  return {
    allCollection: collectionList,
   
  };
};

export default connect(mapStateToProps)(AddNft);
