import axiosInstance from "./AxiosInstance";

//fetch all the zklogin record
export function getZKLoginApi(filterData) {
    let url = `user/zklogins-user?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;
    if (filterData?.pageNumber) {
        url += `?pageNumber=${filterData?.pageNumber}`;
    }
    
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }
   
    return axiosInstance.get(
        `${url}`,
    );
}