import { UserRoleEnum } from '../../../helpers/Enums';

export const MenuList = [
  // {
  //     title: 'Dashboard',
  //     iconStyle: <i className="material-icons">grid_view</i>,
  //     to: 'dashboard',
  //     role: [UserRoleEnum.ADMIN, UserRoleEnum.SUB_ADMIN]
  // },
  {
    title: 'Users',
    iconStyle: <i className="material-symbols-outlined">person</i>,
    to: 'users',
    role: [UserRoleEnum.ADMIN, UserRoleEnum.SUB_ADMIN],
  },
  {
    title: 'Transactions',
    iconStyle: <i className="material-icons">description</i>,
    to: 'transactions',
    role: [UserRoleEnum.ADMIN, UserRoleEnum.SUB_ADMIN],
  },
  {
    title: 'Collections',
    iconStyle: <i className="material-icons">description</i>,
    to: 'collections',
    role: [UserRoleEnum.ADMIN, UserRoleEnum.SUB_ADMIN],
  },
  {
    title: 'Nfts Marketplace',
    iconStyle: <i className="material-icons">description</i>,
    to: 'nfts-marketplace',
    role: [UserRoleEnum.ADMIN, UserRoleEnum.SUB_ADMIN],
  },
  {
    title: 'ZK Login',
    iconStyle: <i className="material-icons">description</i>,
    to: 'zk-login',
    role: [UserRoleEnum.ADMIN, UserRoleEnum.SUB_ADMIN],
  },
  {
    title: 'Guest Users',
    iconStyle: <i className="material-icons">description</i>,
    to: 'guest-users',
    role: [UserRoleEnum.ADMIN, UserRoleEnum.SUB_ADMIN],
  },
];
