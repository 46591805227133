import axiosInstance from '../services/AxiosInstance';


export function getTokens() {
    //axios call
    return axiosInstance.get(
        `/market/user-balance`,
    );
}
export function getAllTokens() {
    //axios call
    return axiosInstance.get(
        `/market/list-tokens`,
    );
}


export function getTokenFee(tokenId) {
    return axiosInstance.get(
        `/market/token-fee?inputTokenId=${tokenId}`,
    );
}


export function withdrawTokenApi(data) {
    return axiosInstance.post(
        `/market/withdraw-tokens`,
        data
    );
}