import { useEffect, useState } from "react";
import { getAllTokens, getTokens } from "../services/TokensService";

const useAllToken = (isAllToken = true) => {
    const [allTokens, setAllToken] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchAllToken = async () => {
            try {
                const allToken = isAllToken ? await getAllTokens() : await getTokens();
                const tokenData = allToken.data.data.map((token) => ({
                    ...token,
                    label: <div className='d-flex align-items-center gap-3'>
                        <span className={token?.symbol === "SNT" ? 'bg-secondary' : ''}>
                            <img src={token?.logoUri} height="30px" width="30px" alt="" />
                        </span>
                        {token?.symbol}
                    </div>
                }));
                setAllToken(tokenData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        fetchAllToken();
    }, []);

    return {
        allTokens,
        isTokenLoading: loading,
        setTokenLoading: setLoading,
    };
}
export default useAllToken;