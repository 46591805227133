import {
    login,
    resendOtp,
    resetPassword,
    saveTokenInLocalStorage,
    signUp,
    verifyOtp,
} from '../../services/AuthService';
import { UserAuthErrors } from '../../helpers/Enums';


import { SIGNUP_CONFIRMED_ACTION, SIGNUP_FAILED_ACTION, OTP_CONFIRMED_ACTION, RESEND_OTP_CONFIRMED_ACTION, RESEND_OTP_FAILED_ACTION, OTP_FAILED_ACTION, LOGIN_CONFIRMED_ACTION, LOGIN_FAILED_ACTION, LOADING_TOGGLE_ACTION, LOGOUT_ACTION, RESET_PASSWORD_CONFIRMED_ACTION, RESET_PASSWORD_FAILED_ACTION } from './AuthTypes';



export function signupAction(email, password, firstName, lastName, navigate) {

    return (dispatch) => {
        signUp(email, password, firstName, lastName)
            .then((response) => {
                dispatch(confirmedSignupAction(response.data));
                localStorage.setItem('email', email)
                navigate('/verify');
            })
            .catch((error) => {
                dispatch(signupFailedAction(error.response.data.message));
            });
    };
}

export function resetPasswordAction(email, password, otp, navigate) {

    return (dispatch) => {
        resetPassword(email, password, otp)
            .then((response) => {
                dispatch({
                    type: RESET_PASSWORD_CONFIRMED_ACTION
                })
                navigate('/login');
            })
            .catch((error) => {
                dispatch({
                    type: RESET_PASSWORD_FAILED_ACTION,
                    payload: error?.response?.data?.message
                })
            });
    };
}

export function verifyOtpAction(email, otp, navigate) {

    return (dispatch) => {
        verifyOtp(email, otp)
            .then((response) => {
                localStorage.removeItem("email")
                saveTokenInLocalStorage(response.data);
                let role = { name: "NATIVE" }
                const user = {
                    idToken: response.data.data.token,
                    email: response.data.data.user.email,
                    role: response.data.data.user.role || role
                }
                dispatch(confirmedOTPAction(user));
                navigate('/dashboard');
            })
            .catch((error) => {
                dispatch(otpFailedAction(error.response.data.message));
            });
    };
}

export function resendOtpAction(email, type) {

    return (dispatch) => {
        resendOtp(email, type)
            .then((response) => {
                dispatch(confirmedResendOTPAction(response.data.message));
            })
            .catch((error) => {
                dispatch(failedResendOTPAction(error.response.data.message));
            });
    };
}

export function Logout(navigate) {
    localStorage.removeItem('userDetails');
    navigate('/login');
    //history.push('/login');

    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, navigate) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                if (response.data.status === 200) {
                    saveTokenInLocalStorage(response.data.data);
                    let role = { name: "NATIVE" }
                    const user = {
                        idToken: response.data.data.token,
                        email: email,
                        role: response.data.data.type || role
                    }
                    dispatch(loginConfirmedAction(user));
                    navigate('/users');
                } else {
                    dispatch(loginFailedAction(response.data.message))
                    if (response.data.message === UserAuthErrors.NOT_VERIFIED) {
                        localStorage.setItem("email", email)
                        navigate('/verify')
                    }
                }

            })
            .catch((error) => {
                dispatch(loginFailedAction(error?.response?.data?.message));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}
export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function confirmedOTPAction(payload) {
    return {
        type: OTP_CONFIRMED_ACTION,
        payload,
    };
}

export function otpFailedAction(message) {
    return {
        type: OTP_FAILED_ACTION,
        payload: message,
    };
}

export function confirmedResendOTPAction(message) {
    return {
        type: RESEND_OTP_CONFIRMED_ACTION,
        payload: message,
    };
}

export function failedResendOTPAction(message) {
    return {
        type: RESEND_OTP_FAILED_ACTION,
        payload: message,
    };
}





export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
