import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserBalanceTypeEnum } from '../../../../helpers/Enums';
import { displayDecimal, replaceUnderscore } from '../../../../helpers/Utils';
import {
  getTransactionAction,
  loadingToggleAction,
} from '../../../../store/actions/transactions/TransactionAction';
import TransactionDetailModal from './TransactionDetail';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { TransactionStatusEnum } from '../../../../helpers/Enums';
import swal from 'sweetalert';
import { updateTransactionStatusAction } from '../../../../store/actions/transactions/TransactionAction';
import Select from "react-select";
const options = [
  { value: "10", label: "10" },
  { value: '50', label: "50" },
  { value: '100', label: "100" },
  { value: '200', label: "200" },

];
const AllTransactions = ({ allTransactions, loading, type, meta, sortBy, search ,status}) => {
  const dispatch = useDispatch();
  const checkTxStatus = new Set([
    TransactionStatusEnum.PENDING,
    TransactionStatusEnum.ERROR,
  ]);

  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getTransactionAction({ type, sortBy,search ,status}));
  }, [dispatch, type, sortBy, search, status]);

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [pageSize, setPageSize] = useState(options[0])

  const onPageChange = (i) => {
    dispatch(loadingToggleAction(true));
    dispatch(
      getTransactionAction({
        type,
        status,
        pageNumber: i,
        pageSize: meta?.pageSize,
      })
    );
  };
  const handleChangePageSize = (e) => {
    setPageSize(e)
    dispatch(loadingToggleAction(true));
    dispatch(
      getTransactionAction({
        type,
        status,
        pageSize: e.value,
      })
    );
  }
  return (
    <>
      <div id="history_wrapper" className="table-responsive dataTablehistory">
        <div className="dataTables_wrapper no-footer">
          <table
            id="example"
            className="table shadow-hover dataTable display"
            style={{ minWidth: '845px' }}
          >
            <thead>
              <tr>
                <th>Token</th>
                <th>Type</th>
                <th>Date</th>
                <th>Status</th>
                <th> Amount</th>
                <th> Game Name</th>
                <th> To</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {allTransactions?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      to={'#'}
                      className="market-title d-flex align-items-center"
                    >
                      <div
                        className={`market-icon   d-flex align-items-center justify-content-center ${item?.inputTokenInfo?.symbol === 'SNT' &&
                          'bg-secondary'
                        } `}
                      >
                        <img
                          src={
                            'https://firebasestorage.googleapis.com/v0/b/colorapps-87bc6.appspot.com/o/senet%3Asui.png?alt=media&token=aa0d4c52-fbc2-411d-8d71-14a0cf148464'
                          }
                          width="30"
                          alt=""
                        />
                      </div>
                      {/* Token will have only Senet blue icon for now so commenting this */}
                      {/* {item?.type === UserBalanceTypeEnum.CONVERT && (
                        <>
                          <i class="fa-solid fa-arrow-right mx-2"></i>
                          <div
                            className={`market-icon  d-flex align-items-center justify-content-center ${item?.outputTokenInfo?.symbol === 'SNT' &&
                              'bg-secondary'
                              } `}
                          >
                            <img
                              src={item?.outputTokenInfo?.logoUri}
                              width="30"
                              alt=""
                            />
                          </div>
                        </>
                      )} */}
                    </Link>
                  </td>

                  <td className="text-uppercase">
                    {replaceUnderscore(item?.type)}
                  </td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  <td>{item?.status}</td>

                  <td>
                    {displayDecimal(item?.inputAmount)}
                    {item?.type === UserBalanceTypeEnum.CONVERT && (
                      <>
                        <i class="fa-solid fa-arrow-right mx-2"></i>
                        {displayDecimal(item?.outputAmount)}
                      </>
                    )}
                  </td>
                  <td>{item?.gameId?.name || '-'}</td>

                  <td>

                    {(item.to_address) ?
                      <div className="mb-2 d-flex gap-2 ">
                        {' '}
                        <p style={{ maxWidth: '100px' }} className="oneline-text">
                          {item.to_address}
                        </p>
                        <i class="fa-regular fa-copy cursor-pointer link-btn"></i>
                      </div> : "-"}
                  </td>

                  <td className="text-end">
                    <div className="d-flex justify-content-end">
                      {checkTxStatus.has(item?.status) && (
                        <div className="d-flex justify-content-end">
                          <span
                            className="btn btn-primary shadow btn-xs sharp me-3"
                            onClick={() => {
                              swal({
                                title: 'Are you sure?',
                                text: 'Once accepted, you will not be able to revert this!',
                                icon: 'warning',
                                buttons: ['Cancel', 'Accept'],
                              }).then((willDelete) => {
                                if (willDelete) {
                                  dispatch(
                                    updateTransactionStatusAction(
                                      item.id,
                                      TransactionStatusEnum.PROCESSING
                                    )
                                  );
                                }
                              });
                            }}
                          >
                            <i class="fa-solid fa-check"></i>
                          </span>
                          <span
                            className="btn btn-danger shadow btn-xs sharp me-3"
                            onClick={() => {
                              swal({
                                title: 'Are you sure?',
                                text: 'Once rejected, you will not be able to revert this!',
                                icon: 'warning',
                                dangerMode: true,
                                buttons: ['Cancel', 'Reject'],
                              }).then((willDelete) => {
                                if (willDelete) {
                                  dispatch(
                                    updateTransactionStatusAction(
                                      item.id,
                                      TransactionStatusEnum.CANCEL
                                    )
                                  );
                                }
                              });
                            }}
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </span>
                        </div>
                      )}
                      <Link
                        to={'#'}
                        className="btn btn-primary shadow btn-xs sharp me-3"
                        onClick={() => {
                          setSelectedTransaction(item);
                          setDetailModal(true);
                        }}
                      >
                        <i class="fa-regular fa-eye"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}

              {!loading && allTransactions?.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No Transaction Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Showing {meta?.pageNumber + 1} to {meta?.pageCount} of{' '}
              {meta?.pageSize} entries
            </div>
            <div className='d-flex align-items-center'>
              <span className='me-2 fs-14 text-white'>PageSize  {" "}:</span>
              <Select
                className="custom-react-select "
                defaultValue={pageSize}
                value={pageSize}
                onChange={handleChangePageSize}
                options={options}
                menuPlacement="auto"
                minMenuHeight={300}
                style={{
                  lineHeight: "40px",
                  color: "#7e7e7e",
                  paddingLeft: " 15px",
                }}
              />
            </div>

            {meta?.pageCount > 1 && (
              <div
                className="dataTables_paginate paging_simple_numbers mb-0"
                id="application-tbl1_paginate"
              >
                <Link
                  className="paginate_button previous "
                  to="/transactions"
                  onClick={() => onPageChange(0)}
                >
                  <i className="fa fa-angle-double-left"></i>
                </Link>
                <span>
                  <PaginationControl
                    page={meta?.pageNumber + 1}
                    between={3}
                    total={meta?.total}
                    limit={meta.pageSize}
                    changePage={(page) => {
                      onPageChange(page - 1);
                    }}
                    ellipsis={1}
                  />
                </span>
                <Link
                  className="paginate_button next"
                  to="/transactions"
                  onClick={() => onPageChange(meta?.pageCount - 1)}
                >
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedTransaction && (
        <TransactionDetailModal
          open={detailModal}
          setOpen={setDetailModal}
          data={selectedTransaction}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ transactions }) => {
  const { transactionsList, showLoading, meta } = transactions;
  return {
    allTransactions: transactionsList,
    meta: meta,
    loading: showLoading,
  };
};

export default connect(mapStateToProps)(AllTransactions);
