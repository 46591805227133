import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import ScrollToTop from "./layouts/ScrollToTop";
import { ThemeContext } from "../context/ThemeContext";
import Users from "./pages/Users/Users";
import Transactions from "./pages/Transactions/Transactions";
import AddCollection from "./pages/Collection/AddCollection";
import AddNft from "./pages/NFT/AddNft";
import Collection from "./pages/Collection/Collection";
import NFTListing from "./pages/NFT/NFTListing";
import ViewUser from "./pages/Users/ViewUser";
import ZKLoginListing from "./pages/ZKLogin/ZKLoginListing";
import GuestUserListing from "./pages/GuestUser/GuestUserListing";


const Markup = () => {

  const allroutes = [
    { url: "/users", component: <Users /> },
    // { url: "dashboard", component: <Dashboard2 /> },
    { url: "transactions", component: <Transactions /> },
    { url: "users", component: <Users /> },
    { url: "view-user", component: <ViewUser/> },
    { url: 'add-collection', component: <AddCollection /> },
    { url: 'add-nft', component: <AddNft /> },
    { url: 'collections', component: <Collection /> },
    { url: 'nfts-marketplace', component: <NFTListing /> },
    { url: 'zk-login', component: <ZKLoginListing /> },
    { url: 'guest-users', component: <GuestUserListing /> },

  ];

  return (
    <>
      <Routes>
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={data.component}
            />
          ))}
        </Route>
        <Route
          path="*"
          element={<Navigate to="/users" replace />}
        />
      </Routes>
      <ScrollToTop />

    </>
  );
};


function MainLayout() {
  const { menuToggle } = useContext(ThemeContext);
  return (
    <div id="main-wrapper" className={`show ${menuToggle ? "menu-toggle" : ""}`}>
      <Nav />
      <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
    </div>
  )

};

export default Markup;
