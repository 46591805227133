import {
    getAllUsersApi,
    getViewUserApi,
    updateUserRoleApi,
    updateUserStatusApi,
  } from '../../../services/UsersService';
  import {
    CONFIRMED_GET_USERS,
    LOADING_TOGGLE_ACTION,
    CONFIRMED_EDIT_USER_ACTION,
  } from './UsersTypes';
  import { toastError, toastSuccess } from '../../../services/ToastService';
  export function getUsersAction(filterData) {
    return (dispatch) => {
      getAllUsersApi(filterData)
        .then((response) => {
          dispatch({
            type: CONFIRMED_GET_USERS,
            payload: {
              ...response.data,
              meta: {
                ...response.data.meta,
                sortBy: filterData?.sortBy,
                search: filterData?.search
              },
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: CONFIRMED_GET_USERS,
            payload: [],
          });
        });
    };
  }
  
  export function updateUserStatusAction(id, status) {
    return (dispatch, state) => {
      updateUserStatusApi(id, status)
        .then((response) => {
          dispatch({
            type: CONFIRMED_EDIT_USER_ACTION,
            payload: response?.data?.data,
          });
          toastSuccess(response?.data?.message);
        })
        .catch((err) => {
          toastError(err.response?.data?.message);
        });
    };
  }
  
  export function updateUserRoleAction(id, role) {
    return (dispatch, state) => {
      updateUserRoleApi(id, role)
        .then((response) => {
            dispatch({
                type: CONFIRMED_EDIT_USER_ACTION,
                payload: response?.data?.data,
              });
              toastSuccess(response?.data?.message);
            })
        .catch((err) => {
          toastError(err?.response?.data?.message);
        });
    };
}
  
export function getViewUsersAction(filterData) {
  return (dispatch) => {
    getViewUserApi(filterData)
      .then((response) => {
        dispatch({
          type: CONFIRMED_GET_USERS,
          payload: {
            ...response.data,
            meta: {
              ...response.data.data.meta,
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: CONFIRMED_GET_USERS,
          payload: [],
        });
      });
  };
}
  export function loadingToggleAction(status) {
    return {
      type: LOADING_TOGGLE_ACTION,
      payload: status,
    };
  }