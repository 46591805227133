import React, { useState } from 'react';
import AllUsers from './components/AllUsers';
import Select from "react-select";
const sortOptions = [
    { value: 'ASC', label: "Sort by ASC" },
    { value: 'DESC', label: "Sort by DESC" },

];


const Users = () => {
    // eslint-disable-next-line no-unused-vars
    const [selectedOption, setSelectedOption] = useState(null)
    const [selectedSort, setSelectedSort] = useState(sortOptions[1])
    const [userCount, setUseCount] = useState(0)
    const [search, setSearch] = useState(null);


    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card">
                        <div className="card-header border-0 pb-2 row">
                            <div className="col-xl-3">
                                <h4 className="heading me-2">All Users
                                    <span className='ms-2 fs-18'>{userCount}</span>
                                </h4>
                            </div>
                            <div className="col-xl-9">
                                <div className='row justify-content-end'>
                                    <div className="col-xl-3">
                                        {/* <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}

                                            placeholder="Select Filters"
                                            style={{
                                                lineHeight: "40px",
                                                color: "#7e7e7e",
                                                paddingLeft: " 15px",
                                            }}
                                        /> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here..."
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-xl-2">
                                        <Select
                                            className="custom-react-select mb-xl-0 mb-3 w-100"
                                            defaultValue={selectedSort}
                                            value={selectedSort}
                                            onChange={setSelectedSort}
                                            options={sortOptions}
                                            placeholder="Sort by"
                                            style={{
                                                lineHeight: "40px",
                                                color: "#7e7e7e",
                                                paddingLeft: " 15px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="card-body pt-2">
                            <AllUsers type={selectedOption?.value} sortBy={selectedSort?.value} setUseCount={setUseCount} search={search} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Users;
