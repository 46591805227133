import { TransactionStatusEnum, UserBalanceTypeEnum } from './Enums';

export const checkTransactionType = new Set([
  UserBalanceTypeEnum.GAME_REWARD,
  UserBalanceTypeEnum.SPIN_REWARD,
  UserBalanceTypeEnum.DEPOSIT,
  UserBalanceTypeEnum.WITHDRAW,
]);
export const TransactionFilterOptions = [
  { value: '', label: 'All' },
  { value: UserBalanceTypeEnum.TRANSFER, label: 'Transfer' },
  { value: UserBalanceTypeEnum.DEPOSIT, label: 'Deposit' },
  { value: UserBalanceTypeEnum.WITHDRAW, label: 'Withdraw' },
  { value: UserBalanceTypeEnum.CONVERT, label: 'Convert' },
  { value: UserBalanceTypeEnum.SPIN_REWARD, label: 'Spin Reward' },
  { value: UserBalanceTypeEnum.GAME_REWARD, label: 'Game Reward' },
];

export const TransactionStatusOptions = [
  { value: '', label: 'All' },
  {
    value: TransactionStatusEnum.CONFIRM,
    label: TransactionStatusEnum.CONFIRM,
  },
  {
    value: TransactionStatusEnum.PENDING,
    label: TransactionStatusEnum.PENDING,
  },
  {
    value: TransactionStatusEnum.REJECTED,
    label: TransactionStatusEnum.REJECTED,
  },
];
export const PaginationOptions = [
  { value: "10", label: "10" },
  { value: '50', label: "50" },
  { value: '100', label: "100" },
  { value: '200', label: "200" },

];
export const sortOptions = [
  { value: 'ASC', label: 'Sort by ASC' },
  { value: 'DESC', label: 'Sort by DESC' },
];