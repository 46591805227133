import { CONFIRMED_GET_TOKENS, LOADING_TOGGLE_ACTION, FAILED_GET_TOKENS } from "../../actions/tokens/TokenTypes";

const initialState = {
    tokensList: [],
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export default function TokensReducer(state = initialState, actions) {

    if (actions.type === CONFIRMED_GET_TOKENS) {
        return {
            ...state,
            tokensList: actions.payload,
            showLoading: false
        };
    }
    if (actions.type === FAILED_GET_TOKENS) {
        return {
            ...state,
            tokensList: [],
            showLoading: false,
            errorMessage: actions.payload
        };
    }

    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    return state;
}
