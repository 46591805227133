import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import axiosInstance from './AxiosInstance'
export function signUp(email, password, firstName, lastName) {
    //axios call
    const postData = {
        email,
        password,
        firstName,
        lastName
    };
    return axiosInstance.post(
        `user/register`,
        postData,
    );
}

export function verifyOtp(email, otp) {
    //axios call
    const postData = {
        email,
        otp
    };
    return axiosInstance.post(
        `user/verify-email`,
        postData,
    );
}
export function resendOtp(email, type) {
    //axios call
    const postData = {
        email,
        type: type
    };
    return axiosInstance.post(
        `/user/send-otp`,
        postData,
    );
}
export function resetPassword(email, password, otp) {
    //axios call
    const postData = {
        email,
        password,
        otp
    };
    return axiosInstance.post(
        `/user/reset-password`,
        postData,
    );
}
export function login(email, password) {
    const postData = {
        email,
        password,
    };
    return axiosInstance.post(
        `admin/login`,
        postData,
    );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}


export function saveTokenInLocalStorage(tokenDetails) {
    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
        return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}
