import React, { useState } from 'react';
import Select from "react-select";
import { Button, Row, Col, Modal } from 'react-bootstrap';
import { TransactionStatusEnum, UserBalanceTypeEnum } from '../../../../helpers/Enums';
import { checkTransactionType } from '../../../../helpers/Sets';
import { replaceUnderscore } from '../../../../helpers/Utils';
import TokenInfo from './TokenInfo';
import { updateTransactionStatusAction } from '../../../../store/actions/transactions/TransactionAction';
import { useDispatch, connect } from 'react-redux';


const options = [
    { value: TransactionStatusEnum.PROCESSING, label: TransactionStatusEnum.PROCESSING },
    { value: TransactionStatusEnum.BROADCAST, label: TransactionStatusEnum.BROADCAST },
    { value: TransactionStatusEnum.PENDING, label: TransactionStatusEnum.PENDING },
    { value: TransactionStatusEnum.CONFIRM, label: TransactionStatusEnum.CONFIRM },
    { value: TransactionStatusEnum.CANCEL, label: TransactionStatusEnum.CANCEL },
    { value: TransactionStatusEnum.ERROR, label: TransactionStatusEnum.ERROR },

];
const TransactionDetailModal = ({ open, setOpen, data, meta }) => {
    const [selectedOption, setSelectedOption] = useState({ value: data?.status, label: data?.status })
    const checkTxStatus = new Set([TransactionStatusEnum.ERROR])
    const dispatch = useDispatch()


    return (
        <Modal className="fade" show={open} centered="true">
            <Modal.Header>
                <Modal.Title>Transaction Detail</Modal.Title>
                <Button
                    onClick={() => setOpen(false)}
                    variant=""
                    className="btn-close"
                ></Button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {
                        data?.txHash && (
                            <>
                                <Col md="3 "> Tx Hash </Col>
                                <Col md="9" className='mb-3'>
                                    <div className="mb-2 d-flex gap-2 ">
                                        <div className='oneline-text'>
                                            {data?.txHash}
                                        </div>
                                        <i class="fa-regular fa-copy cursor-pointer link-btn"></i>
                                        <i class="fa-solid fa-arrow-up-right-from-square cursor-pointer link-btn"></i>
                                    </div>
                                </Col>
                            </>
                        )
                    }

                    {
                        (data?.type === UserBalanceTypeEnum.CONVERT || data?.type === UserBalanceTypeEnum.TRANSFER) && (
                            <>
                                <Col md="3 "> From </Col>
                                <Col md="9" className='mb-3'>
                                    <TokenInfo
                                        address={data.inputTokenInfo?.address}
                                        logoUri={data?.inputTokenInfo?.logoUri}
                                        inputAmount={data?.inputAmount}
                                        symbol={data?.inputTokenInfo?.symbol}
                                    />
                                </Col>
                                <Col md="3">To</Col>
                                <Col md="9" className='mb-3'>
                                    <TokenInfo
                                        address={data.outputTokenInfo?.address}
                                        logoUri={data?.outputTokenInfo?.logoUri}
                                        inputAmount={data?.outputAmount}
                                        symbol={data?.outputTokenInfo?.symbol}
                                    />
                                </Col>
                            </>
                        )
                    }

                    {
                        checkTransactionType.has(data?.type) && (
                            <>
                                <Col md="3 "> Token Info </Col>
                                <Col md="9" className='mb-3'>
                                    <TokenInfo
                                        address={data.inputTokenInfo?.address}
                                        logoUri={data?.inputTokenInfo?.logoUri}
                                        inputAmount={data?.inputAmount || 0}
                                        symbol={data?.inputTokenInfo?.symbol}
                                    />

                                </Col>
                            </>
                        )
                    }

                    <Col md="3">Type</Col>
                    <Col md="9" className='mb-3'>
                        <p className="mb-0 text-break text-uppercase">{replaceUnderscore(data?.type)}</p>
                    </Col>
                    <Col md="3">Create at</Col>
                    <Col md="9" className='mb-3'>
                        <p className="mb-0">{new Date(data.createdAt).toDateString()}</p>
                    </Col>

                    {
                        checkTxStatus.has(data?.status) && (
                            <>
                                <Col md="3">Error</Col>
                                <Col md="9" className='mb-3'>
                                    <p className="mb-0">{data.errorDescription}</p>

                                </Col>
                            </>

                        )
                    }

                </Row>


            </Modal.Body>
        </Modal>
    )
}


const mapStateToProps = ({ transactions }) => {
    const { showLoading, meta } = transactions
    return {
        meta: meta,
        loading: showLoading
    };
};

export default connect(mapStateToProps)(TransactionDetailModal);


