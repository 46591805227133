import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    OTP_CONFIRMED_ACTION,
    OTP_FAILED_ACTION,
    RESEND_OTP_CONFIRMED_ACTION,
    RESEND_OTP_FAILED_ACTION,
    SIGNUP_FAILED_ACTION,
    SIGNUP_CONFIRMED_ACTION,
    RESET_PASSWORD_FAILED_ACTION,
    RESET_PASSWORD_CONFIRMED_ACTION
} from '../actions/AuthTypes';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { toastError } from '../../services/ToastService';


const initialState = {
    auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
        role: null
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
    otpSent: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === SIGNUP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: action.payload.message,
            showLoading: false,
        };
    }
    if (action.type === LOGIN_CONFIRMED_ACTION || action.type === OTP_CONFIRMED_ACTION) {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
            otpSent: false,

        };
    }

    if (action.type === LOGOUT_ACTION || action.type === RESET_PASSWORD_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            otpSent: false,
            showLoading: false,
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
            },
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION ||
        action.type === OTP_FAILED_ACTION ||
        action.type === RESEND_OTP_FAILED_ACTION ||
        action.type === RESET_PASSWORD_FAILED_ACTION
    ) {
        toastError(action.payload)
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
            otpSent: false
        };
    }
    if (action.type === RESEND_OTP_CONFIRMED_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: action.payload,
            showLoading: false,
            otpSent: true
        };
    }
    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


const persistConfig = {
    key: 'auth',
    storage,
    whitelist: ['auth'], // Only persist the 'auth' part of the state
};
export const persistedAuthReducer = persistReducer(persistConfig, AuthReducer);