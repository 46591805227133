import { getZKLoginApi } from "../../../services/ZKLoginAction";
import {  GET_ZK_LOGIN, LOADING_TOGGLE_ACTION } from "./ZKTypes";

//fetch all the zklogin record
export function getZKLoginAction(filterData) {
    return (dispatch, state) => {
        getZKLoginApi(filterData)
            .then((response) => {
                dispatch(
                    {
                        type: GET_ZK_LOGIN,
                        payload: {
                            ...response.data, meta: {
                                ...response.data.meta,
                                sortBy: filterData?.sortBy
                                
                            }
                        },
                    }
                );
            })
            .catch((error) => {
                dispatch(
                    {
                        type: GET_ZK_LOGIN,
                        payload: {
                            data: [],
                            meta: state.meta
                        }
                    }
                );
            });

    };
}


export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}