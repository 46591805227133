import { toastError, toastSuccess } from "../../../services/ToastService";
import { getTransactionApi, updateTransactionStatusApi } from "../../../services/Transaction";
import { CONFIRMED_GET_TRANSACTIONS, LOADING_TOGGLE_ACTION, CONFIRMED_EDIT_TRANSACTION_ACTION } from "./TransactionTypes";

export function getTransactionAction(filterData) {
    return (dispatch, state) => {
        getTransactionApi(filterData)
            .then((response) => {
                dispatch(
                    {
                        type: CONFIRMED_GET_TRANSACTIONS,
                        payload: {
                            ...response.data, meta: {
                                ...response.data.meta,
                                type: filterData?.type, sortBy: filterData?.sortBy,
                                status: filterData?.status
                            }
                        },
                    }
                );
            })
            .catch((error) => {
                dispatch(
                    {
                        type: CONFIRMED_GET_TRANSACTIONS,
                        payload: {
                            data: [],
                            meta: state.meta
                        }
                    }
                );
            });

    };
}

export function updateTransactionStatusAction(id, status) {
    return (dispatch, state) => {
        updateTransactionStatusApi(id, status)
            .then((response) => {
                dispatch(
                    {
                        type: CONFIRMED_EDIT_TRANSACTION_ACTION,
                        payload: response?.data?.data?.data
                    }
                );
                toastSuccess(response?.data?.message)
            })
            .catch((err) => {
                toastError(err.response.data.message)
            })

    };
}


export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}