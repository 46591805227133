

import "./Loader.css";
const Loader = ({className, size='md'}) => {
    return (
        <div className={`loading ${className}`}>
             <div className="text-center">
                <span className={`spinner-border spinner-border-${size}`}role="status" aria-hidden="true"></span>
            </div>
        </div>
    )
}

export default Loader;