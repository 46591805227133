import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  getZKLoginAction,
  loadingToggleAction,
} from '../../../store/actions/zkLogin/ZKAction';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from 'react-router-dom';
import { sortOptions } from '../../../helpers/Sets';
import Select from 'react-select';


const ZKLoginListing = ({ ZKLoginListing, loading, meta }) => {
  const dispatch = useDispatch();

  const [tooltipText, setTooltipText] = useState({}); // State to store tooltip text for each item
  const [selectedSort, setSelectedSort] = useState(sortOptions[1]);

//reducer action dispatch
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getZKLoginAction({sortBy:selectedSort.value}));
  }, [dispatch, selectedSort]);

  const onPageChange = (i) => {
    dispatch(loadingToggleAction(true));
    dispatch(
      getZKLoginAction({
        pageNumber: i,
        pageSize: meta?.pageSize,
      })
    );
  };

  // Handle text copy to clipboard
  const handleCopy = (text, key) => {
    navigator.clipboard.writeText(text);
    setTooltipText((prev) => ({
      ...prev,
      [key]: 'Copied!', // Set the tooltip to display 'Copied!'
    }));

    // Reset the tooltip after 2 seconds
    setTimeout(() => {
      setTooltipText((prev) => ({
        ...prev,
        [key]: text, // Restore the original text in the tooltip
      }));
    }, 2000);
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card position-relative">
            <div className="card-header border-0 pb-2 row">
              <div className="col-md-4 col-xl-4">
                <h4 className="heading me-2">ZK(Zero Knowledge) Login </h4>
              </div>
              <div className="col-md-8 col-xl-8">
                <div className="row justify-content-end">
                 
                  
                  <div className=" col-12 col-md-6 col-xl-3">
                    <Select
                      className="custom-react-select mb-xl-0 mb-3 w-100"
                      defaultValue={selectedSort}
                      value={selectedSort}
                      onChange={setSelectedSort}
                      options={sortOptions}
                      placeholder="Sort by"
                      style={{
                        lineHeight: '40px',
                        color: '#7e7e7e',
                        paddingLeft: ' 15px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body pt-2">
              <div
                className="table-responsive dataTablehistory"
              >
                <div className="dataTables_wrapper no-footer">
                  <table
                    id="example"
                    className="table shadow-hover dataTable display"
                  >
                    <thead>
                      <tr>
                        <th>Nonce</th>
                        <th>Public Key</th>
                        <th>Randomness</th>
                        <th>Salt</th>
                        <th>Sui Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <tr>
                          <td colSpan="5">
                            <div className="text-center">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          </td>
                        </tr>
                      )}

                      {!loading &&
                        ZKLoginListing?.length > 0 &&
                        ZKLoginListing?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                className="tooltip-container"
                                onClick={() => handleCopy(item?.nonce, index)}
                              >
                                <span className="text-ellipsis">
                                  {item?.nonce}
                                </span>
                                <div className="tooltip-text">
                                  {tooltipText[index] || item?.nonce}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className="tooltip-container"
                                onClick={() =>
                                  handleCopy(item?.randomness, index + 'pub')
                                }
                              >
                                <span className="text-ellipsis">
                                  {item?.randomness}
                                </span>
                                <div className="tooltip-text">
                                  {tooltipText[index + 'pub'] ||
                                    item?.randomness}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className="tooltip-container"
                                onClick={() =>
                                  handleCopy(item?.publicKey, index + 'rand')
                                }
                              >
                                <span className="text-ellipsis">
                                  {item?.publicKey}
                                </span>
                                <div className="tooltip-text">
                                  {tooltipText[index + 'rand'] ||
                                    item?.publicKey}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                className="tooltip-container"
                                onClick={() =>
                                  handleCopy(item?.salt, index + 'salt')
                                }
                              >
                                <span className="text-ellipsis">
                                  {item?.salt}
                                </span>
                                <div className="tooltip-text">
                                  {tooltipText[index + 'salt'] || item?.salt}
                                </div>
                              </div>
                            </td>
                            <td >
                              <div
                                className="tooltip-container"
                                onClick={() =>
                                  handleCopy(item?.suiAddress, index + 'sui')
                                }
                              >
                                <span className="text-ellipsis">
                                  {item?.suiAddress}
                                </span>
                                <div className="tooltip-text">
                                  {tooltipText[index + 'sui'] ||
                                    item?.suiAddress}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      {!loading && ZKLoginListing?.length === 0 && (
                        <tr>
                          <td colSpan={6} style={{ textAlign: 'center' }}>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {!loading && (
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                      <div className="dataTables_info">
                        Showing {meta?.pageNumber + 1} to {meta?.pageCount} of{' '}
                        {meta?.pageSize} entries
                      </div>

                      {meta?.pageCount > 1 && (
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="application-tbl1_paginate"
                        >
                          <Link
                            className="paginate_button previous "
                            to="/transactions"
                            onClick={() => onPageChange(0)}
                          >
                            <i className="fa fa-angle-double-left"></i>
                          </Link>
                          <span>
                            <PaginationControl
                              page={meta?.pageNumber + 1}
                              between={3}
                              total={meta?.total}
                              limit={meta.pageSize}
                              changePage={(page) => {
                                onPageChange(page - 1);
                              }}
                              ellipsis={1}
                            />
                          </span>
                          <Link
                            className="paginate_button next"
                            to="/transactions"
                            onClick={() => onPageChange(meta?.pageCount - 1)}
                          >
                            <i className="fa fa-angle-double-right"></i>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

//connect to reducer
const mapStateToProps = ({ zkLogin }) => {
  const { zkLoginList: ZKLoginListing, showLoading, meta } = zkLogin;
  return {
    ZKLoginListing,
    meta: meta,
    loading: showLoading,
  };
};

export default connect(mapStateToProps)(ZKLoginListing);
