import { CONFIRMED_CREATE_NFT, GET_NFT, LOADING_TOGGLE_ACTION } from "../../actions/nft/NFTTypes";


const initialState = {
    nftList: [],
    meta: {
        pageCount: 0,
        pageNumber: 0,
        pageSize: 10,
        total: 0,
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};
export function NFTReducer(state = initialState, actions) {
    if (actions.type === GET_NFT) {
        return {
            ...state,
            nftList: actions.payload?.data?.adsNft,
            meta: actions.payload?.meta,
            showLoading: false
        };
    }
    if (actions.type === CONFIRMED_CREATE_NFT) {
        const newNftList = [...state.nftList];
        newNftList.push(actions.payload);

        return {
            ...state,
            nftList: newNftList,
            showLoading: false,
            successMessage: "NFT Created Successfully!",
        };
    }


    if (actions.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: actions.payload,
        };
    }
    return state;
}
