
import axiosInstance from './AxiosInstance'

export function getCollectionApi(filterData) {
    let url = `ads-collection?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;


    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }

    return axiosInstance.get(
        `${url}`,
    );
}

export const AddCollectionApi = (data) => {
    return axiosInstance.post('/ads-collection/create', data);
};