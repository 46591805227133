
import axiosInstance from './AxiosInstance'

export function getAllUsersApi(filterData) {
    let url = `/admin/users?sortBy=${filterData?.sortBy ? filterData?.sortBy : 'DESC'}`;
    if (filterData?.pageNumber) {
        url += `&pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }
    if (filterData?.type) {
        url += `&type=${filterData?.type}`;
    }
    if (filterData?.search) {
        url += `&search=${filterData?.search}`;
    }
    return axiosInstance.get(
        `${url}`,
    );

}

export function updateUserStatusApi(id, status) {

    return axiosInstance.put(
        `admin/user/status/${id}?blocked=${status}`,
    );
}

export function updateUserRoleApi(id, role) {

    return axiosInstance.put(
        `admin/user/role/${id}?type=${role}`,
    );
}
export function getViewUserApi(filterData) {
    let url = `admin/users/${filterData?.id}`;
    if (filterData?.pageNumber) {
        url += `?pageNumber=${filterData?.pageNumber}`;
    }
    if (filterData?.pageSize) {
        url += `&pageSize=${filterData?.pageSize}`;
    }
    
    return axiosInstance.get(
        `${url}`,
    );
}