import React, { useEffect, useState } from 'react';
import { getGuestUserApi } from '../../../services/GuestUserAction';
import { Link } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const GuestUserListing = () => {
  const [getGuestUser, setGetGuestUser] = useState([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    fetchGuestUsers();
  }, []);
  /**
   * Fetches guest users from the API and updates the state.
   * @param {{ pageNumber: number, pageSize: number }} pagination - The pagination options.
   */
  const fetchGuestUsers = async (pagination) => {
    try {
      // Show the loading indicator
      setloading(true);

      // Fetch the guest users
      const response = await getGuestUserApi(pagination);

      // Update the state with the new data
      setGetGuestUser(response?.data);

      // Hide the loading indicator
      setloading(false);
    } catch (error) {
      console.error('Error fetching guest users:', error);
    }
  };

  const onPageChange = (pageNumber) => {
    // Fetch the guest users for the new page
    fetchGuestUsers({
      pageNumber,
      pageSize: getGuestUser?.meta?.pageSize,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card position-relative">
            <div className="card-header border-0 pb-2 row">
              <div className="col-md-4 col-xl-4">
                <h4 className="heading me-2">Guest Users </h4>
              </div>
              <div className="col-md-8 col-xl-8">
                <div className="row justify-content-end"></div>
              </div>
            </div>
            <div className="card-body pt-2">
              <div className="table-responsive dataTablehistory">
                <div className="dataTables_wrapper no-footer">
                  <table
                    id="example"
                    className="table shadow-hover dataTable display"
                  >
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Wallet Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading && (
                        <tr>
                          <td colSpan="5">
                            <div className="text-center">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          </td>
                        </tr>
                      )}

                      {!loading &&
                        getGuestUser?.wallets?.length > 0 &&
                        getGuestUser?.wallets?.map((wallet, index) => (
                          <tr key={index}>
                            <td>{wallet?.id}</td>
                            <td>{wallet?.address}</td>
                          </tr>
                        ))}
                      {!loading && getGuestUser?.wallets?.length === 0 && (
                        <tr>
                          <td colSpan={6} style={{ textAlign: 'center' }}>
                            No Record Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {!loading && (
                    <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
                      <div className="dataTables_info">
                        Showing {getGuestUser?.meta?.currentPage + 1} to{' '}
                        {getGuestUser?.meta?.totalCount} of{' '}
                        {getGuestUser?.meta?.pageSize} entries
                      </div>

                      {getGuestUser?.meta?.totalCount > 1 && (
                        <div
                          className="dataTables_paginate paging_simple_numbers mb-0"
                          id="application-tbl1_paginate"
                        >
                          <Link
                            className="paginate_button previous "
                            to="/transactions"
                            onClick={() => onPageChange(0)}
                          >
                            <i className="fa fa-angle-double-left"></i>
                          </Link>
                          <span>
                            <PaginationControl
                              page={getGuestUser?.meta?.currentPage + 1}
                              between={3}
                              total={getGuestUser?.meta?.totalCount}
                              limit={getGuestUser?.meta.pageSize}
                              changePage={(page) => {
                                onPageChange(page - 1);
                              }}
                              ellipsis={1}
                            />
                          </span>
                          <Link
                            className="paginate_button next"
                            to="/transactions"
                            onClick={() =>
                              onPageChange(getGuestUser?.meta?.totalCount - 1)
                            }
                          >
                            <i className="fa fa-angle-double-right"></i>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestUserListing;
