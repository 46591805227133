import React, { useState } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import AllTransactions from './components/AllTransactions';
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom';
import {
  TransactionFilterOptions,
  TransactionStatusOptions,
} from '../../../helpers/Sets';

const sortOptions = [
  { value: 'ASC', label: 'Sort by ASC' },
  { value: 'DESC', label: 'Sort by DESC' },
];

const Transactions = ({ loading }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSort, setSelectedSort] = useState(sortOptions[1]);
  const [search, setSearch] = useState(null);
  const[selectedTransactionStatus, setSelectedTransactionStatus] = useState(null);

  return (
    <>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card position-relative">
            {loading && <Loader className={'rounded'} />}
            <div className="card-header border-0 pb-2 row">
              <div className="col-md-4 col-xl-4">
                <h4 className="heading me-2">Transaction History</h4>
              </div>
              <div className="col-md-8 col-xl-8">
                <div className="row justify-content-end">
                  <div className="col-12 col-md-6 col-xl-3 ">
                    <div className="input-group search-area mb-3">
                      <span className="input-group-text">
                        <Link to={'#'}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                              fill="var(--primary)"
                            />
                          </svg>
                        </Link>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <Select
                      className="custom-react-select mb-xl-0 mb-3 w-100"
                      defaultValue={selectedOption}
                      onChange={setSelectedOption}
                      options={TransactionFilterOptions}
                      placeholder="Select Filters"
                      style={{
                        lineHeight: '40px',
                        color: '#7e7e7e',
                        paddingLeft: ' 15px',
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-xl-3">
                    <Select
                      className="custom-react-select mb-xl-0 mb-3 w-100"
                      defaultValue={selectedTransactionStatus}
                      onChange={setSelectedTransactionStatus}
                      options={TransactionStatusOptions}
                      placeholder="Filter By Status"
                      style={{
                        lineHeight: '40px',
                        color: '#7e7e7e',
                        paddingLeft: ' 15px',
                      }}
                    />
                  </div>

                  <div className=" col-12 col-md-6 col-xl-3">
                    <Select
                      className="custom-react-select mb-xl-0 mb-3 w-100"
                      defaultValue={selectedSort}
                      value={selectedSort}
                      onChange={setSelectedSort}
                      options={sortOptions}
                      placeholder="Sort by"
                      style={{
                        lineHeight: '40px',
                        color: '#7e7e7e',
                        paddingLeft: ' 15px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body pt-2">
              <AllTransactions
                type={selectedOption?.value}
                sortBy={selectedSort?.value}
                search={search}
                status={selectedTransactionStatus?.value}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ transactions }) => {
  const { showLoading } = transactions;
  return {
    loading: showLoading,
  };
};

export default connect(mapStateToProps)(Transactions);
