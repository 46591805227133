import { Button, Modal } from 'react-bootstrap';
import { useEffect, useMemo } from 'react';

const ImageViewModal = ({ modalOpen, setModalOpen, selectedProductImage }) => {
    const closeModal = useMemo(() => {
        return () => setModalOpen(false);
    }, [setModalOpen]);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };

    }, [closeModal]);

    return (
        <>
            <Modal className="fade" show={modalOpen} centered="true">
                <Modal.Header>
                    <Modal.Title>Preview</Modal.Title>
                    <Button
                        onClick={closeModal}
                        variant=""
                        className="btn-close"
                    ></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <img
                            src={selectedProductImage}
                            className="w-100"
                            alt="Selected Product"
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ImageViewModal;
