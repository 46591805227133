import axios from 'axios';
import { store } from '../store/store';
const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    if (token) {
        config.headers.authorization = token;
    }

    config.params = config.params || {};
    return config;
});

export default axiosInstance;
