const TokenInfo = ({ address, logoUri, inputAmount, symbol }) => {
    return (
        <div >
            <div className="mb-2 d-flex gap-2 ">
                <div className='oneline-text'>
                    {address}
                </div>
                <i class="fa-regular fa-copy cursor-pointer link-btn"></i>
            </div>
            <div className="market-title d-flex align-items-center">
                <div className={`market-icon  d-flex align-items-center justify-content-center ${symbol === 'SNT' && "bg-secondary"} `}>
                    <img src={logoUri} width="25" alt="" />
                </div>
                <div className='mx-2'>
                    {inputAmount} {symbol}
                </div>

            </div>

        </div>
    )
}

export default TokenInfo